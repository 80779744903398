import { integrationType } from './integrations';

export class Options {
  // only set by user
  apiKey;
  elementId;
  elementSelector;
  customerFieldId; //customerFieldId
  customerFieldSelector; //customerFieldSelector
  search;
  autoScrollOffset;

  // set by default
  apiUrl;
  integrationType;
  theme;
  validateOnChange;

  // auth setting
  useAuthorizationHeaderForApiKey;
  useAuthorizationHeaderForToken;

  language;
  country;
  latitude;
  longitude;
  h3Index;
  isSearchAllowed;

  token;
}

export function getDefaultOptions() {
  let options = new Options();

  options.apiUrl = 'https://api.autoaddress.com/3.0/';
  options.hideCustomerFields = false;
  options.integrationType = integrationType.AUTOADDRESS_FORM;
  options.theme = 'material-theme';
  options.autoScrollOffset = 0;
  // When this setting is set to false it will use the query string "key" to pass the api key rather than auth header
  options.useAuthorizationHeaderForApiKey = true;
  // When this setting is set to false it will use the query string "token" to pass the api key rather than auth header
  options.useAuthorizationHeaderForToken = false;
  options.validateOnChange = true;
  return options;
}
