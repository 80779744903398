export class Settings {
  wrapperIsInput;
  locationWeight;
  countryWeight;
  theme;
  inputHeight;

  placeholder;
  initialPlaceholder;

  // state settings
  itemSelected; // user has selected drilldown/lookup. reset on autocomplete;
  initCompleted;
  selectedAddressId; // user has selected lookup. store for formatenteredaddress. clear on changeCountry
  selectedCountry;
  selectedLanguage;
  selectedAddress; // cached result for lookup api results
  hasFormValuesChanged; // used to determine if we need to call a formatenteredaddress for the user
  selectedIndex; // track which autocomplete item is selected

  static getDefaultSettings() {
    let settings = new Settings();

    settings.theme = themes.MATERIAL;
    settings.version = '3.1.70';
    settings.addressSelected = false;
    settings.placeholder = '';
    settings.initCompleted = false;

    settings.inputHeight = 56;
    settings.smCutoff = 359;
    settings.mdCutoff = 590;
    settings.dropDownCutoff = 18;
    settings.windowInnerWidthCutoff = 480;
    settings.windowInnerHeightCutoff = 480;
    settings.smallViewport = 361;
    settings.hasFormValuesChanged = false;
    settings.selectedIndex = -1;

    return settings;
  }
}

export const themes = {
  MATERIAL: 'material-theme',
};

// export function getDefaultSettings(){
//     let settings = new Settings();

//     return settings;
// }
