class Utils {
  static extend(...objects) {
    let target = {};
    objects.forEach(source => {
      for (var prop in source) {
        if (typeof source[prop] === 'object') {
          target[prop] = this.extend(target[prop], source[prop]);
        } else if (source[prop] !== undefined) {
          target[prop] = source[prop];
        }
      }
    });
    return target;
  }

  static getHrefFromResponse(response, endpoint) {
    return response.links?.find(link => link.rel == endpoint)?.href;
  }

  static getMergedAddress(lookupResponse, reformatResponse) {
    // If I have a lookup response, merge the address data with the reformat data
    if (lookupResponse && reformatResponse) {
      return {
        ...lookupResponse,
        address: {
          id: lookupResponse.address.id,
          language: lookupResponse.address.language,
          charset: lookupResponse.address.charset,
          ...reformatResponse.address,
        },
      };
      // Otherwise just return the lookup or reformat data i.e. manual entered address with no lookup
    } else {
      return lookupResponse || reformatResponse;
    }
  }

  static getElements(container) {
    return Array.from(container.querySelectorAll('input, select, textarea'));
  }
}

module.exports = Utils;
